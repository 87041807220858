<template>
  <div class="screen">
    <el-card shadow="always" class="screenHead">
      <div style="font-size:60px;margin-bottom:10px">青春中国系列展演活动</div>
      <div>主办方：江苏省青少年文化交流中心</div>
    </el-card>
    <div class="leftSide">
      <!-- <div class="signShow1" style="margin-bottom:20px">
        <div style="margin:20px"><el-button type="primary" class="title">考场信息</el-button></div>
        书画第一场（8:00 - 9:30）
      </div> -->
      <!-- <div class="signShow2">
        <div style="margin:20px"><el-button type="success" class="title">签到成功</el-button></div>
        <img :src="child.workName" style="height:calc(20vh)">
        <div class="onlive_txt">姓名：{{child.childName}}</div>
        <div class="onlive_txt">展演组别：{{child.team}}</div>
        <div class="onlive_txt">展演项目：{{child.item2}}</div>
      </div> -->
      <el-card shadow="always">
        <div style="margin-bottom:20px"><el-button type="success" class="title">签到成功</el-button></div>
        <div class="signShow">
          <img :src="child.workName" style="height:calc(45vh)">
          <div class="onlive_txt">姓名：{{child.childName}}</div>
          <div class="onlive_txt">展演组别：{{child.team}}</div>
          <div class="onlive_txt">展演项目：{{child.item2}}</div>
        </div>
      </el-card>
    </div>
    <div class="rightSide">
      <el-card shadow="always">
        <div style="margin-bottom:20px"><el-button type="primary" class="title" style="margin-left:-190px">已签到人员</el-button>
        <div style="float:left;height:45px;line-height:45px;padding-top:8px">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="[10, 20, 30, 40, 50, 60, 100]" :page-size="limit" layout="total, sizes" :total="total"></el-pagination>
        </div>
        </div>
        <div class="signShow">
          <el-table stripe border :data="list1" style="width: 49%;float:left;clear:both">
            <!-- <el-table-column label="序号" width="60" align="center"><template slot-scope="scope">{{(page-1)*limit+scope.$index+1}}</template></el-table-column> -->
            <el-table-column label="序号" prop="childId" width="60" align="center"></el-table-column>
            <el-table-column prop="childName" label="考生姓名" align="center"></el-table-column>
            <el-table-column prop="team" label="展演组别" align="center"></el-table-column>
            <el-table-column label="展演项目" align="center">
              <template slot-scope="scope">{{scope.row.item1}} - {{scope.row.item2}}</template>
            </el-table-column>
          </el-table>
          <el-table stripe border :data="list2" style="width: 49%;float:right;">
            <!-- <el-table-column label="序号" width="60" align="center"><template slot-scope="scope">{{(page-1)*limit+scope.$index+16}}</template></el-table-column> -->
            <el-table-column label="序号" prop="childId" width="60" align="center"></el-table-column>
            <el-table-column prop="childName" label="考生姓名" align="center"></el-table-column>
            <el-table-column prop="team" label="展演组别" align="center"></el-table-column>
            <el-table-column label="展演项目" align="center">
              <template slot-scope="scope">{{scope.row.item1}} - {{scope.row.item2}}</template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import enrollApi from '../api/enroll.js'

export default {
  data() {
    return {
      id:0,
      list1:null,
      list2:null,
      page: 1, // 当前页
      limit: 30, // 每页记录数
      total: 0 , // 总记录数
      timer: "",
      child:{}
    }
  },
  methods: {
    getlist(){
      this.addPage()
      var that = this
      console.log("abc")
      enrollApi.getSignByPlaceId(this.id,this.page,this.limit).then(res => {
        if(res.data.success){
          that.list1 = res.data.data.list1
          that.list2 = res.data.data.list2
          that.total = res.data.data.total
          that.child = res.data.data.child
        }
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.getlist.call(this)
    },
    handleCurrentChange(val) {
      this.page=val;
      this.getlist.call(this)
    },
    addPage(){
      if(this.page*this.limit>this.total){
        this.page = 1
      }else{
        this.page++
      }
    }
  },
  created() {
    if(this.$route.query.place > 0) {
      this.id = this.$route.query.place
      this.timer = setInterval(this.getlist, 5000)
      this.getlist.call(this)
    }
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
}
</script>
<style scoped>
.screen{
  margin: 0 auto;
  padding: 20px;
}
.screenHead{
  margin-bottom: 20px;
  background-image: url(../assets/11.jpg);
  color:#fff;
}
.signShow1,.signShow2{
  border-radius:4px;
  border: 1px solid #EBEEF5;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  height: 130px;
}
.signShow2{
  height: calc(100vh - 365px);
}
.signShow{
  clear: both;
  height: calc(100vh - 320px);
}
.leftSide {
  width: 30%;
  height: 100%;
  float: left;
}
.rightSide {
  width: 68%;
  height: 100%;
  float: right;
}
.block{
  float: left;
  padding-bottom: 20px;
}
.title{
  font-size: 20px;
  /* padding-bottom: 20px; */
}
.onlive_txt{
  margin: 10px;
  font-size: 16px;
}
</style>